export const routePaths = {
  root: "/",
  auth: "/auth",
  authChildren: {
    signIn: "/login",
    register: "/register",
  },
  orders: "/orders",
  restaurants: "/restaurants",
  menus: "/menus",
  documentation: "/documentation",
  help: "/help",
};
