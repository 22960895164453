import InfoIcon from "./InfoIcon";
import ErrorIcon from "./ErrorIcon";
import SuccessIcon from "./SuccessIcon";

const AlertInfo = ({ text }) => {
  return (
    <div className="flex bg-blue-100 rounded-md p-4 mt-5">
      <div className="flex">
        <div className="flex-shrink-0">
          <InfoIcon />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-normal font-open text-blue-800">
            {text}
          </h3>
          <p className="mt-1 font-medium font-open text-blue-700 font-sans">
            {" "}
          </p>
        </div>
      </div>
    </div>
  );
};

const AlertError = ({ text }) => {
  return (
    <div className="flex bg-red-100 rounded-md p-4 mt-5">
      <div className="flex">
        <div className="flex-shrink-0">
          <ErrorIcon />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-normal font-open text-red-800">{text}</h3>
          <p className="mt-1 font-medium font-open text-red-700 font-sans"> </p>
        </div>
      </div>
    </div>
  );
};

const AlertSuccess = ({ text }) => {
  return (
    <div className="flex bg-green-100 rounded-md p-4 mt-5">
      <div className="flex">
        <div className="flex-shrink-0">
          <SuccessIcon />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-normal font-open text-green-800">
            {text}
          </h3>
          <p className="mt-1 font-medium font-open text-green-700 font-sans">
            {" "}
          </p>
        </div>
      </div>
    </div>
  );
};

const Alert = ({ type, text }) => {
  switch (type) {
    case "info":
      return <AlertInfo text={text} />;
    case "error":
      return <AlertError text={text} />;
    case "success":
      return <AlertSuccess text={text} />;
    default:
      return null;
  }
};

export default Alert;
