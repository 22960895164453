import { current } from "@reduxjs/toolkit";
import { DealTypes } from "../../../features/misc/containers/Restaurants/RestaurantHeader/RestaurantHeader.container";

export const addItemToCart = (cartItems, cartItemToAdd) => {
  // TODO : check if item is identical before add

  return [
    ...cartItems,
    { ...cartItemToAdd, quantity: 1, order: cartItems.length },
  ];
};

export const removeItemToCart = (cartItems, cartItemToRemove) => {
  const existingCartItem = cartItems.find(
    (cartItem) => `${cartItem.itemId}` === `${cartItemToRemove.itemId}`
  );

  if (existingCartItem.quantity === 1) {
    return cartItems.filter(
      (cartItem) => `${cartItem.itemId}` !== `${cartItemToRemove.itemId}`
    );
  }

  return cartItems.map((cartItem) =>
    `${cartItem.itemId}` === `${cartItemToRemove.itemId}`
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  );
};

export const changeItemQuantityCart = (cartItems, { id, quantity, order }) => {
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.itemId === id && cartItem.order === order
  );

  if (existingCartItem && quantity === 0) return cartItems.filter((cartItem) => cartItem.order !== order);
  else if (existingCartItem && quantity > 0) {
    return cartItems.map((cartItem) =>
      `${cartItem.itemId}` === id && cartItem.order === order
        ? { ...cartItem, quantity }
        : cartItem
    );
  }
};

export const addDealToCart = (cartDeals, dealToAdd) => {

  const foundDeal = cartDeals.find(dealElem => dealElem.dealId === dealToAdd.dealId)
  if (foundDeal) return [...cartDeals]

  return [
    ...cartDeals,
    { ...dealToAdd }
  ]
}

export const removeDealFromCart = (cartDeals, dealIdToRemove) => {
  return [...cartDeals.filter(cartDealElem => cartDealElem.dealId != dealIdToRemove)]
}

export const clearSpendXGetYDeals = (cartDeals = [], cartItems, deals = []) => {
  const [bogoDeal] = cartDeals.filter(cartDealElem => cartDealElem.type !== DealTypes.spend_x_get_y)

  let totalCart = cartItems.reduce((accumulatedQuantity, cartItem) => {
    return ( accumulatedQuantity + cartItem.quantity * cartItem.totalPrice );
  }, 0)

  let bogoDealExists = bogoDeal && cartItems.some(itemElem => itemElem.instanceId === bogoDeal.discountItems[0].itemId)

  let newActiveDeals = []
  if (bogoDealExists) {
    totalCart -= bogoDeal.discount
    newActiveDeals.push(bogoDeal)
  }

  for (let i = 0; i < deals.length; i++) {
    const dealElem = deals[i];
    
    if (dealElem.type === DealTypes.spend_x_get_y) {
      if (totalCart > (dealElem.minAmount * 100)) {
        const newActiveDeal = { dealId: dealElem._id, promoType: "deal", discount: dealElem.discount , type: DealTypes.spend_x_get_y }
        if (dealElem.discount && dealElem.discount.percentage) {
          newActiveDeal.discountedAmount = totalCart * dealElem.discount.percentage
        }
        newActiveDeals.push(newActiveDeal)
      }
    }
  }

  return newActiveDeals
}