import { createAsyncThunk } from "@reduxjs/toolkit";

import to from "await-to-js";

import { login } from "@/features/auth/api";
import { getUserInfo, register } from "../../../features/auth/api";

import handlingError from "@/utils/handlingError";

export const tryLogin = createAsyncThunk(
  "auth/tryLogin",
  async ({ email, password }, { rejectWithValue }) => {
    const [error, data] = await to(login(email, password));

    if (error) {
      return rejectWithValue(handlingError(error));
    }

    return data;
  }
);

export const tryRegister = createAsyncThunk(
  "auth/tryRegister",
  async ({ email, password, prenom, nom, telephone }, { rejectWithValue }) => {
    const [error, data] = await to(
      register(email, password, prenom, nom, telephone)
    );

    if (error) {
      return rejectWithValue(handlingError(error));
    }

    return data;
  }
);

export const getUserInfoAsync = createAsyncThunk(
  "auth/getUserInfo",
  async (token, { rejectWithValue }) => {
    const [error, data] = await to(getUserInfo(token));

    if (error) {
      return rejectWithValue(handlingError(error));
    }

    return data;
  }
);
