import { createGlobalStyle } from "styled-components";

import convertThemeColorsToRootColors from "../utils/convertThemeColorsToRootColors";

const GlobalStyle = createGlobalStyle`
    *, ::after, ::before {
        box-sizing: border-box;
        font-family: var(--family-primary);
        padding: 0;
        margin: 0;
    }

    body {
        overflow-x: hidden;
    }

    :root {
        ${({ theme }) => {
          return convertThemeColorsToRootColors(theme.colors);
        }}

        ${({ theme }) => {
          return convertThemeColorsToRootColors(theme.family);
        }}
    }

    a {
        text-decoration: none;
    }

    h1, h2, h3 {
        font-family: Poppins;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .swal2-container {
        z-index: 200000 !important;
    }
`;

export default GlobalStyle;
