import { requestHttp } from "@/libs/axios";

export const APIfetchRestaurantsHeaders = (idRestaurant, deliveryType) => {
  return requestHttp({
    method: "GET",
    url: `/v2/restaurants/${idRestaurant}/items/menu-type?menuType=${deliveryType}`,
  });
};

export const APIfetchRestaurantsItemDetails = (idRestaurant, idItem) => {
  return requestHttp({
    method: "GET",
    url: `/v2/restaurants/${idRestaurant}/items/${idItem}/details`,
  });
};

export const APIfetchRestaurants = ({ limit, page } = {}) => {
  return requestHttp({
    method: "GET",
    url: `/v1/test-dashboard/restaurants`,
    params: {
      limit,
      page,
    },
  });
};

export const APIfetchRestaurantDetails = (restaurantId, deliveryType) => {
  return requestHttp({
    method: "GET",
    url: `/v1/restaurant/${restaurantId}?menuType=${deliveryType}`,
  });
};

export const APIUpdateRestaurant = (restaurantId, data) => {
  return requestHttp({
    method: "PUT",
    url: `/v1/test-dashboard/restaurants/${restaurantId}`,
    data,
  });
};

export const APIfetchOrders = ({
  deliveryType,
  status,
  restaurantId,
  update,
  limit,
  page,
} = {}) => {
  return requestHttp({
    method: "GET",
    url: `/v1/test-dashboard/orders`,
    params: {
      deliveryType,
      status,
      restaurantId,
      update,
      limit,
      page,
    },
  });
};

export const APIfetchOrderDetails = (orderId) => {
  return requestHttp({
    method: "GET",
    url: `/v1/test-dashboard/orders/${orderId}`,
  });
};

export const APIUpdateOrder = ({ idOrder, status }) => {
  return requestHttp({
    method: "PUT",
    url: `/v1/test-dashboard/orders/${idOrder}/status`,
    data: {
      status,
    },
  });
};

export const APICheckoutOrder = ({
  idRestaurant,
  cart,
  deliveryType,
  paymentMethodId,
  noteForRestaurant,
  noteForRider,
  deliveryAdressId,
  facturationAdressId,
  promotions,
  savedCard,
  price,
  code,
  includeDisposableItems,
  riderTipAmount,
}) => {
  return requestHttp({
    method: "POST",
    url: `/v2/orders/checkout`,
    data: {
      appVersion: "web",
      paymentMethodId,
      noteForRestaurant,
      noteForRider,
      restaurantId: idRestaurant,
      deliveryAdressId:
        deliveryType === "pickup" ? undefined : deliveryAdressId,
      facturationAdressId,
      cart,
      deliveryType,
      promotions,
      savedCard,
      price: +price.toFixed(2),
      code,
      includeDisposableItems,
      riderTipAmount,
    },
  });
};
