import React from "react";

const FullScreenLoader = () => {
  return (
    <div className="flex items-center justify-center h-screen w-screen bg-gray-200">
      <div className="flex flex-col items-center">
        <svg
          className="animate-spin h-10 w-10 text-gray-800"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm12 0a8 8 0 100-16 8 8 0 000 16z"
          ></path>
        </svg>
        <p className="mt-2 text-center text-gray-800 font-medium">
          Vérification de l'authentification en cours..
        </p>
      </div>
    </div>
  );
};

export default FullScreenLoader;
