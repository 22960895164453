import { getUserInfoAsync, tryLogin } from "./auth.services";
import { errorInitialValue } from "./auth.slice";

const authAsyncActions = (builder) => {
  builder.addCase(tryLogin.pending, (state) => {
    state.loading = "pending";
    state.errors = errorInitialValue;
  });

  builder.addCase(tryLogin.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;
    state.token = action.payload.data.token;
    state.user = action.payload.data.user;
  });

  builder.addCase(tryLogin.rejected, (state, action) => {
    state.loading = "failed";
    state.errors = action.payload;
  });

  builder.addCase(getUserInfoAsync.fulfilled, (state, action) => {
    state.loading = "idle";
    state.errors = errorInitialValue;
    state.token = action.meta.arg;
    state.user = {
      email: action.payload.data.user.email,
      nom: action.payload.data.user.lastName,
      prenom: action.payload.data.user.firstName,
      telephone: action.payload.data.user.phoneNumber,
    };
  });
};

export default authAsyncActions;
