import { requestHttp } from "@/libs/axios";

export const APIfetchAddresses = () => {
  return requestHttp({
    method: "GET",
    url: `/v1/user/adress`,
  });
};

export const APIfetchRegisterCards = () => {
  return requestHttp({
    method: "GET",
    url: `/v1/user/cards`,
  });
};

export const APIfetchShippingCost = (
  restaurantId,
  deliveryAdressId,
  orderPrice
) => {
  return requestHttp({
    method: "GET",
    url: `/v1/user/shipping/fees/${restaurantId}?adressId=${deliveryAdressId}&orderPrice=${orderPrice}`,
  });
};

export const APIDeteleAddress = (addressId) => {
  return requestHttp({
    method: "DELETE",
    url: `/v1/user/adress/${addressId}`,
  });
};

export const APICreateAddress = (values) => {
  return requestHttp({
    method: "POST",
    url: `/v1/user/adress`,
    data: values,
  });
};

export const APICreateCard = (values) => {
  return requestHttp({
    method: "POST",
    url: `/v1/user/payment-method`,
    data: values,
  });
};

export const APIDeteleCard = (cardId) => {
  return requestHttp({
    method: "DELETE",
    url: `/v1/user/payment-method/${cardId}`,
  });
};

export const APICheckPromoCode = (code, restaurantId) => {
  return requestHttp({
    method: "POST",
    url: `/v1/promo-code/check`,
    data: {
      code,
      restaurantId,
    },
  });
};

export const APICheckReferralCode = (code, restaurantId) => {
  return requestHttp({
    method: "POST",
    url: `/v1/referral-code/check`,
    data: {
      code,
      restaurantId,
    },
  });
};

export const APIfetchTipsInfo = (restaurantId, deliveryType, orderPrice) => {
  return requestHttp({
    method: "GET",
    url: `/v1/user/tips/info?restaurantId=${restaurantId}&deliveryType=${deliveryType}&orderPrice=${orderPrice}`,
  });
};
