import {
  Navigate,
  useNavigate,
  useParams,
  useRoutes,
  useSearchParams,
} from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";

import { setAuthToken } from "@/utils/setAuthToken";

import { publicRoutes } from "./public.routes";
//import { protectedRoutes } from "./protected.routes";
import { useEffect, useState } from "react";
import {
  selectAuthToken,
  selectIsAuthenticated,
} from "@/store/reducers/auth/auth.selector";
import { selectCart } from "@/store/reducers/cart/cart.selectors";

import { getUserInfoAsync } from "../store/reducers/auth/auth.services";
import { Toastify } from "@/libs/toast";
import FullScreenLoader from "../components/UICs/FullScreenLoader/FullScreenLoader";

import { cartSliceActions } from "../store/reducers/cart/cart.slice";

import isEmpty from "@/utils/isEmpty";
import { authSliceActions } from "../store/reducers/auth/auth.slice";

const AppRoutes = ({ isAuthenticated, userToken, cart }) => {
  const [isConnected, setIsConnected] = useState(isAuthenticated);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const useAppDispatch = () => useDispatch();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const token = searchParams.get("token");
      const type = searchParams.get("type");
      const code = searchParams.get("code");

      if (token !== null) {
        setLoading(true);
        const res = await dispatch(getUserInfoAsync(token));

        if (getUserInfoAsync.fulfilled.match(res)) {
          //Toastify(`Connecté avec succès.`, "success", 3000);

          setAuthToken(token);
        }

        if (getUserInfoAsync.rejected.match(res)) {
          dispatch(authSliceActions.logout());
        }

        setLoading(false);
      }

      if (type !== null && ["delivery", "pickup"].includes(type)) {
        dispatch(cartSliceActions.changeDeliveryType(type));
      }

      if (code !== null && !isEmpty(code)) {
        dispatch(cartSliceActions.handleChangeCode(code));
      }
    }

    fetchData();

    setIsConnected(isAuthenticated);
  }, [isAuthenticated]);

  setAuthToken(userToken);

  if (
    cart.code &&
    cart.codeValidity &&
    new Date().getTime() - cart.codeValidity > 1800000
  ) {
    dispatch(cartSliceActions.handleChangeCode(""));
  } else if (cart.code && !cart.codeValidity) {
    dispatch(cartSliceActions.handleChangeCode(""));
  }

  const element = useRoutes(publicRoutes);

  return !loading ? <>{element}</> : <FullScreenLoader />;
};

const mapStateToProps = createStructuredSelector({
  isAuthenticated: selectIsAuthenticated,
  userToken: selectAuthToken,
  cart: selectCart,
});

export default connect(mapStateToProps)(AppRoutes);
