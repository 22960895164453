import { DealTypes } from "../../../features/misc/containers/Restaurants/RestaurantHeader/RestaurantHeader.container";
import {
  addItemToCart,
  removeItemToCart,
  changeItemQuantityCart,
  addDealToCart,
  removeDealFromCart,
  clearSpendXGetYDeals
} from "./cart.utils";

export const addItem = (state, action) => {
  if (state.restaurantId === null) {
    state.restaurantId = action.payload.restaurantId;
  } else if (state.restaurantId !== action.payload.restaurantId) {
    state.restaurantId = action.payload.restaurantId;
    state.cartItems = [];
  }

  state.cartItems = addItemToCart(state.cartItems, action.payload.item);
};

export const clearItemFromCart = (state, action) => {
  state.cartItems = state.cartItems.filter(
    (cartItem) =>
      `${cartItem.itemId}-${cartItem.order}` !==
      `${action.payload.id}-${action.payload.order}`
  );

  if (state.cartItems.length === 0) {
    state.restaurantId = null;
  }
};

export const removeItem = (state, action) => {
  state.cartItems = removeItemToCart(state.cartItems, action.payload);

  if (state.cartItems.length === 0) {
    state.restaurantId = null;
  }
};

export const switchIncludeDisposableItems = (state) => {
  state.includeDisposableItems = !state.includeDisposableItems
}

export const clearCart = (state, action) => {
  state.cartItems = [];
  state.restaurantId = null;
};

export const changeItemQuantity = (state, action) => {
  state.cartItems = changeItemQuantityCart(state.cartItems, action.payload);

  if (state.cartItems.length === 0) {
    state.restaurantId = null;
  }
};

export const changeDeliveryType = (state, action) => {
  state.deliveryType = action.payload;
};

export const handleChangeCode = (state, action) => {
  state.code = action.payload;

  if (action.payload) {
    state.codeValidity = new Date().getTime();
  } else {
    state.codeValidity = null;
  }
};

export const addDeal = (state, action) => {
  state.activeDeals = addDealToCart(state.activeDeals || [], action.payload.deal);
};

export const removeDeal = (state, action) => {
  state.activeDeals = removeDealFromCart(state.activeDeals, action.payload.deal.dealId);
};

export const handleCartDeals = (state, action) => {
  state.activeDeals = clearSpendXGetYDeals(state.activeDeals, state.cartItems, action.payload.deals)
}

export const clearBogoDeals = (state, action) => {
  state.activeDeals = [...(state.activeDeals ? state.activeDeals?.filter(dealElem => dealElem?.type !== DealTypes.bogo) : [])]
}

export const clearAllDeals = (state, action) => {
  state.activeDeals = []
}
