import { lazyImport } from "../utils/lazyImport";

import { routePaths } from "../config";

const { HomeRoutes } = lazyImport(
  () => import("../features/misc"),
  "HomeRoutes"
);

const { AuthRoutes } = lazyImport(
  () => import("../features/auth"),
  "AuthRoutes"
);

export const publicRoutes = [
  {
    path: routePaths.auth + "/*",
    element: <AuthRoutes />,
  },
  {
    path: "/*",
    element: <HomeRoutes />,
  },
];
