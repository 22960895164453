import { useState, useEffect, useContext } from "react";
import { Divider, Typography, Alert } from "@mui/material";
import { useParams } from "react-router-dom";

import { APIfetchRestaurantsItemDetails } from "@/features/misc/api";
import { RestaurantContext } from "@/features/misc/containers/Restaurants/RestaurantHeader/RestaurantHeader.container";

import ModifierGroup from "../ModifierGroup/ModifierGroup.form";

import "./MenuCustomization.styles.scss";

const MenuCustomizationForm = ({
  selectedItem,
  details,
  setDetails,
  customisationItem,
  setCustomisationItem,
  setIsAddBtnDisabled,
  currentResto,
}) => {
  const { restoAvailability, setErrorLoadingItem } =
    useContext(RestaurantContext);
  const { idRestaurant } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [selectedModifierGroups, setSelectedModifierGroups] = useState();
  const [optionsQuantityCheckList, setOptionsQuantityCheckList] = useState([]);

  useEffect(() => {
    setCustomisationItem({ ...customisationItem, selectedModifierGroups });
    handleIsAddBtnDisabled();
  }, [selectedModifierGroups]);

  const verifyModifierGroupsQuantity = () => {
    let verified = true;

    details &&
      details.modifierGroupIds.map((modElem) => {
        if (
          modElem.quantityInfo.minPermittedUnique > 0 ||
          modElem.quantityInfo.minPermitted > 0
        ) {
          const selectedMod =
            selectedModifierGroups &&
            selectedModifierGroups.find(
              (selectedModElem) =>
                selectedModElem.modifierGroupId === modElem._id
            );
          if (!selectedMod) verified = false;
          else {
            if (
              selectedMod.selectedItems.length <
                modElem.quantityInfo.minPermittedUnique ||
              selectedMod.selectedItems.length >
                modElem.quantityInfo.maxPermittedUnique
            )
              verified = false;

            let totalQuantity = 0;
            for (let i = 0; i < selectedMod.selectedItems.length; i++) {
              const selectedItemElem = selectedMod.selectedItems[i];
              totalQuantity += selectedItemElem.quantity;
            }

            if (
              totalQuantity < modElem.quantityInfo.minPermitted ||
              totalQuantity > modElem.quantityInfo.maxPermitted
            )
              verified = false;
          }
        }
      });

    return verified;
  };

  const handleIsAddBtnDisabled = () => {
    let rootVerified = verifyModifierGroupsQuantity();
    if (rootVerified) {
      const disabled = optionsQuantityCheckList.some(
        (elem) => elem.value === false
      );
      setIsAddBtnDisabled(disabled);
    } else setIsAddBtnDisabled(true);
  };

  useEffect(() => {
    if (!details) {
      setLoading(true);

      APIfetchRestaurantsItemDetails(idRestaurant, selectedItem.item._id)
        .then((res) => {
          setDetails(res.data);
          setCustomisationItem({
            itemId: res.data._id,
            price: res.data.priceInfo.value,
            title: res.data.title,
            externalId: res.data.externalId,
            externalData: res.data.externalData,
            selectedModifierGroups,
            availabilityInfo: res.data.availabilityInfo
          });
        })
        .catch((err) => {
          console.error(err);

          if (err?.response?.data) {
            setErrorLoadingItem(err?.response?.data);
          }

          if (err?.response?.data?.message) {
            setError(err?.response?.data?.message);
          } else {
            setError("Une erreur s'est produite");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  if (!selectedItem) return null;

  return (
    <form className="f-menu-customisation">
      {selectedItem.item.image && (
        <div
          className="menu-customisation__image"
          style={{ backgroundImage: `url(${selectedItem.item.image.url})` }}
        ></div>
      )}
      <Typography
        sx={{
          fontSize: "0.875rem",
          marginTop: "1.25rem",
          marginBottom: "1.25rem",
          color: "rgba(102,102,102,1)",
        }}
      >
        {(details && details.description) ||
          "Pas de description pour le moment"}
      </Typography>
      <Divider sx={{ marginBottom: "1rem" }} />
      {!restoAvailability?.restaurantActif && (
        <Alert severity="info">
          {restoAvailability?.closureReason?.message}
        </Alert>
      )}
      {loading ? (
        <h6>Chargement des options...</h6>
      ) : error ? (
        <h6>{error}</h6>
      ) : (
        details && (
          <>
            {details.modifierGroupIds.map((modifierGroupId, idx) => {
              return (
                <ModifierGroup
                  key={idx}
                  modifierGroupIndex={idx}
                  modifierGroupId={modifierGroupId}
                  selectedModifierGroups={selectedModifierGroups}
                  setSelectedModifierGroups={setSelectedModifierGroups}
                  setOptionsQuantityCheckList={setOptionsQuantityCheckList}
                  optionsQuantityCheckList={optionsQuantityCheckList}
                />
              );
            })}
            {currentResto?.legalLink && (
              <p style={{ padding: "10%" }}>
                Pour être informé sur les allergènes présents dans les produits
                proposés par ce restaurant,
                <a
                  target="_blank"
                  style={{ color: "green" }}
                  href={currentResto?.legalLink}
                >
                  &nbsp; Cliquez-ici
                </a>
              </p>
            )}
          </>
        )
      )}
    </form>
  );
};

export default MenuCustomizationForm;
