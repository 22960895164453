import { errorInitialValue } from "./auth.slice";

export const clearAuthError = (state) => {
  state.errors = errorInitialValue;
};

export const logout = (state) => {
  state.loading = "idle";
  state.token = null;
  state.user = null;
};

export const saveRestaurantId = (state, action) => {
  state.restaurantId = action.payload;
};
