import { Box, Radio, Chip, IconButton, Tooltip } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ExpandLess, ExpandMore, Info } from "@mui/icons-material";

import ModifierGroup from "../forms/ModifierGroup/ModifierGroup.form";

import AddRemoveElement from "./AddRemoveElement.uic";

const ReusableModifierOptionRadioWithQty = ({
  modifierGroupId,
  modifierOptionIndex,
  modifierOption,
  setSelectedItems,
  selectedOptionId,
  setSelectedOptionId,
  parentsQuantityCheckList,
  setParentQuantityCheckList,
}) => {
  const radioRef = useRef();
  const [showModifiers, setShowModifiers] = useState(false);
  const [showSelectionAlert, setShowSelectionAlert] = useState(false);
  const [qty, setQty] = useState(modifierOption.quantityInfo.minPermitted || 1);
  const [selectedModifierGroups, setSelectedModifierGroups] = useState();
  const [selectedItem, setSelectedItem] = useState();
  const [isChecked, setIsChecked] = useState(
    modifierOption._id === selectedOptionId ? true : false
  );
  const [optionsQuantityCheckList, setOptionsQuantityCheckList] = useState([]);

  useEffect(() => {
    if (selectedItem)
      setSelectedItem({ ...selectedItem, selectedModifierGroups });
  }, [selectedModifierGroups]);

  useEffect(() => {
    setIsChecked(modifierOption._id === selectedOptionId ? true : false);
  }, [selectedOptionId]);

  useEffect(() => {
    let newSelectedItems = [selectedItem];
    newSelectedItems = newSelectedItems.filter((elem) => elem !== undefined);
    setSelectedItems(newSelectedItems);
  }, [selectedItem]);

  useEffect(() => {
    if (selectedItem) {
      const newSelectedItem = { ...selectedItem };
      newSelectedItem.quantity = qty;
      setSelectedItem(newSelectedItem);
    }
  }, [qty]);

  useEffect(() => {
    handleSubItemsIsAddBtnDisabled();
  }, [selectedModifierGroups, isChecked]);

  const verifyModifierGroupsQuantity = () => {
    if (!isChecked) return true;
    let verified = true;

    modifierOption &&
      modifierOption.modifierGroupIds &&
      modifierOption.modifierGroupIds.map((modElem) => {
        if (
          modElem.quantityInfo.minPermittedUnique > 0 ||
          modElem.quantityInfo.minPermitted > 0
        ) {
          const selectedMod = selectedModifierGroups.find(
            (selectedModElem) => selectedModElem.modifierGroupId === modElem._id
          );
          if (!selectedMod) verified = false;
          else {
            if (
              selectedMod.selectedItems.length <
                modElem.quantityInfo.minPermittedUnique ||
              selectedMod.selectedItems.length >
                modElem.quantityInfo.maxPermittedUnique
            )
              verified = false;

            let totalQuantity = 0;
            for (let i = 0; i < selectedMod.selectedItems.length; i++) {
              const selectedItemElem = selectedMod.selectedItems[i];
              totalQuantity += selectedItemElem.quantity;
            }

            if (
              totalQuantity < modElem.quantityInfo.minPermitted ||
              totalQuantity > modElem.quantityInfo.maxPermitted
            )
              verified = false;
          }
        }
      });

    return verified;
  };

  const handleSubItemsIsAddBtnDisabled = () => {
    let verifiedValue = verifyModifierGroupsQuantity();

    if (verifiedValue)
      verifiedValue = !optionsQuantityCheckList.some(
        (elem) => elem.value === false
      );

    const newList =
      (parentsQuantityCheckList && [...parentsQuantityCheckList]) || [];
    const index = newList.findIndex(
      (elem) => elem.ref === `${modifierGroupId}${modifierOption._id}`
    );
    if (index !== -1)
      newList[index] = {
        ref: `${modifierGroupId}${modifierOption._id}`,
        value: verifiedValue,
      };
    else
      newList.push({
        ref: `${modifierGroupId}${modifierOption._id}`,
        value: verifiedValue,
      });

    setShowSelectionAlert(!verifiedValue);

    setParentQuantityCheckList(newList);
  };

  const handleAddModifierOption = () => {
    if (isChecked) {
      setShowModifiers(false);
      setSelectedOptionId("");
      setSelectedItems([]);
    } else {
      setShowModifiers(true);
      setSelectedOptionId(modifierOption._id);
      const newItem = selectedItem ? { ...selectedItem } : {};
      (newItem.itemId = modifierOption._id),
        (newItem.price = modifierOption.priceInfo.value);
      newItem.title = modifierOption.title;
      newItem.quantity = qty || 1;
      newItem.externalId = modifierOption.externalId;
      newItem.externalData = modifierOption.externalData;
      newItem.selectedModifierGroups = selectedModifierGroups || [];
      newItem.index = modifierOptionIndex + 1;
      setSelectedItem(newItem);
    }
  };

  const hasModifierQuantity = (modifierOption) => {
    let show = true;
    if (
      modifierOption.quantityInfo.minPermitted ===
      modifierOption.quantityInfo.maxPermitted
    ) {
      show = false;
    } else if (
      modifierOption.quantityInfo.minPermitted === 0 &&
      modifierOption.quantityInfo.maxPermitted === 1
    ) {
      show = false;
    }

    return show;
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",

            "@media screen and (max-width: 425px)": {
              flexWrap: "wrap",
            },
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems="center"
            width="100%"
          >
            <Radio
              inputRef={radioRef}
              onClick={(e) => {
                e.stopPropagation();
                handleAddModifierOption();
              }}
              checked={isChecked}
              disabled={!modifierOption.availabilityInfo?.isAvailable}
            />
            <Box
              sx={{
                cursor: "pointer",
                width: "100%",
              }}
              onClick={(e) => {
                e.stopPropagation();

                if (modifierOption.availabilityInfo.isAvailable)
                  handleAddModifierOption();
              }}
            >
              {modifierOption.title}
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems="center"
            width="100%"
          >
            <Box display={"flex"} alignItems={"center"} gap="1rem" width="100%">
              <Box display={"flex"} alignItems="center" width="100%">
                <Chip
                  size="small"
                  label={`+ ${modifierOption.priceInfo.text}`}
                  sx={{ mr: 1 }}
                />
                {hasModifierQuantity(modifierOption) &&
                modifierOption.availabilityInfo.isAvailable ? (
                  <AddRemoveElement
                    defaultValue={qty}
                    minValue={modifierOption.quantityInfo.minPermitted || 0}
                    maxValue={modifierOption.quantityInfo.maxPermitted || 99}
                    disabled={!isChecked}
                    setQty={setQty}
                  />
                ) : (
                  <p>{modifierOption.availabilityInfo.reason}</p>
                )}
              </Box>
              {modifierOption.modifierGroupIds &&
                modifierOption.modifierGroupIds.length > 0 && (
                  <Tooltip
                    title={showModifiers ? "Hide options" : "Show options"}
                  >
                    <IconButton
                      disabled={false}
                      onClick={() => {
                        setShowModifiers(!showModifiers);
                      }}
                    >
                      {showModifiers ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  </Tooltip>
                )}
              {showSelectionAlert && (
                <Tooltip title="Selections requises">
                  <Info style={{ color: "#B71C1C" }} />
                </Tooltip>
              )}
            </Box>
          </Box>
        </Box>
        {modifierOption.modifierGroupIds &&
          modifierOption.modifierGroupIds.length > 0 && (
            <Box
              style={{
                display: showModifiers && isChecked ? "block" : "none",
                border: "2px solid gray",
                padding: "5px",
                borderRadius: "10px",
              }}
            >
              {modifierOption.modifierGroupIds.map((modifierGroupId, idx) => {
                return (
                  <ModifierGroup
                    key={idx}
                    modifierGroupIndex={idx}
                    modifierGroupId={modifierGroupId}
                    selectedModifierGroups={selectedModifierGroups}
                    setSelectedModifierGroups={setSelectedModifierGroups}
                    optionsQuantityCheckList={optionsQuantityCheckList}
                    setOptionsQuantityCheckList={setOptionsQuantityCheckList}
                  />
                );
              })}
            </Box>
          )}
      </Box>
    </>
  );
};

export default ReusableModifierOptionRadioWithQty;
