import React, { useEffect, useState, createContext, useRef } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { connect } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import { Alert as MuiAlert } from "@mui/material";
import Alert from "@/components/UICs/Alert/Alert.uic";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Link as LinkScroll, Element } from "react-scroll";
import { StickyContainer, Sticky } from "react-sticky";
import classnames from "classnames";
import { useDispatch } from "react-redux";

import { Card, CardContent, CardMedia } from "@mui/material";

import { Close } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { colors } from "@/theme/globalVariables";

import { Toastify } from "@/libs/toast";

import { cartSliceActions } from "@/store/reducers/cart/cart.slice";

import {
  APIfetchRestaurants,
  APIfetchRestaurantsHeaders,
} from "@/features/misc/api";

import { authSliceActions } from "@/store/reducers/auth/auth.slice";

import MenuCustomizationForm from "@/features/misc/forms/MenuCustomization/MenuCustomization.form";

import "./RestaurantHeader.styles.scss";
import hasDuplicates from "../../../../../utils/hasDuplicates";
import { APIfetchRestaurantDetails } from "../../../api/restaurants.api";
import { getUserInfoAsync } from "../../../../../store/reducers/auth/auth.services";
import { routePaths } from "../../../../../config/routePaths";
import { createStructuredSelector } from "reselect";
import { selectAuthUser } from "../../../../../store/reducers/auth/auth.selector";
import {
  selectCart,
  selectCartItems,
  selectCartTotal,
  selectCartDeals,
} from "@/store/reducers/cart/cart.selectors";

export const RestaurantContext = createContext();

export const DealTypes = {
  bogo: "buy_x_get_y",
  spend_x_get_y: "spend_x_get_y",
};

const CRestaurantHeader = ({
  addItem,
  saveRestaurantId,
  addDeal,
  removeDeal,
  handleCartDeals,
  clearBogoDeals,
  clearAllDeals,
  cart,
  cartDeals,
  cartTotal,
  cartItems,
}) => {
  const { idRestaurant } = useParams();
  const navigate = useNavigate();

  const [currentResto, setCurrentResto] = useState(null);
  const [restaurantHeaders, setRestaurantHeaders] = useState([]);
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingRestoMenus, setLoadingRestoMenus] = useState(false);
  const [error, setError] = useState("");
  const [errorMenusRestos, setErrorMenusRestos] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [details, setDetails] = useState(null);
  const [customisationItem, setCustomisationItem] = useState({});
  const [customisationPrice, setCustomisationPrice] = useState(0);
  const [isAddBtnDisabled, setIsAddBtnDisabled] = useState(true);
  const [openMore, setOpenMore] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [restoAvailability, setRestoAvailability] = useState(null);
  const [distanceFromTop, setDistanceFromTop] = useState(191);
  const [customHeader, setCustomHeader] = useState(null);
  const [remainingGrantingItems, setRemainingGrantingItems] = useState(1);
  const [openBogoDialog, setOpenBogoDialog] = useState(false);
  const [cancelAutoOpenBogoDialog, setCancelAutoOpenBogoDialog] =
    useState(false);
  const [errorLoadingItem, setErrorLoadingItem] = useState(null);

  const mobileMenuRef = useRef(null);
  const moreButtonRef = useRef(null);

  useEffect(() => {
    setCustomisationPrice(calculateItemCost(customisationItem));
  }, [customisationItem]);

  useEffect(() => {
    if (openMore) {
      document.addEventListener("click", verifClickOut);

      return () => {
        document.removeEventListener("click", verifClickOut);
      };
    }
  }, [openMore]);

  function verifClickOut(event) {
    if (mobileMenuRef.current && moreButtonRef.current) {
      // Vérifie si l'élément cible n'est pas un enfant de myElement
      if (
        !mobileMenuRef.current.contains(event.target) &&
        !moreButtonRef.current.contains(event.target)
      ) {
        setOpenMore(false);
      }
    }
  }

  useEffect(() => {
    setLoading(true);
    APIfetchRestaurantDetails(idRestaurant, cart.deliveryType)
      .then((res) => {
        if (!res.data.restaurant) {
          throw "Aucun restaurant correspondant";
        }

        setCurrentResto(res.data.restaurant);
        saveRestaurantId(idRestaurant);
      })
      .catch((err) => {
        console.error(err);
        setError(
          "Une erreur s'est produite durant l'obtention des données du restaurant"
        );

        if (err.response.data) {
          err.response.data.errors.forEach((error) => {
            Toastify(error.message, "error", 3000);
          });
        }

        navigate(`${routePaths.auth}${routePaths.authChildren.signIn}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [cart.deliveryType]);

  useEffect(() => {
    if (currentResto) {
      setLoadingRestoMenus(true);

      APIfetchRestaurantsHeaders(currentResto.id, cart.deliveryType)
        .then((res) => {
          setRestaurantHeaders(res.data.headers);
          const customHeaderResponse = res.data.deals?.find(
            (dealElem) => dealElem.type === DealTypes.bogo
          );
          setCustomHeader(customHeaderResponse);
          setDeals(res.data.deals);
          setRestoAvailability(res.data.restaurantAvailability);
          if (!customHeaderResponse) clearBogoDeals();
          if (!res.data.deals || !res.data.deals.length) clearAllDeals();
        })
        .catch((err) => {
          console.error(err);
          setErrorMenusRestos("Menu actuellement indisponible");

          if (err.response.data) {
            err.response.data.errors.forEach((error) => {
              Toastify(error.message, "error", 3000);
            });
          }
        })
        .finally(() => {
          setLoadingRestoMenus(false);
        });
    }
  }, [currentResto, cart.deliveryType]);

  useEffect(() => {
    if (selectedItem) setOpen(true);
  }, [selectedItem]);

  useEffect(() => {
    if (!open) handleRemoveSelectedItem();
  }, [open]);

  useEffect(() => {
    const isOpenable =
      customHeader &&
      remainingGrantingItems === 0 &&
      !(
        cartDeals &&
        cartDeals.some((dealElem) => dealElem.dealId === customHeader._id)
      );

    if (isOpenable && !cancelAutoOpenBogoDialog) {
      setOpenBogoDialog(true);
    } else if (!isOpenable && cancelAutoOpenBogoDialog) {
      setCancelAutoOpenBogoDialog(false);
    }
  }, [
    customHeader,
    cartDeals,
    remainingGrantingItems,
    cancelAutoOpenBogoDialog,
  ]);

  const handleRemoveSelectedItem = () => {
    setSelectedItem(null);
    setCustomisationItem({});
    setOpen(false);
    setDetails(null);

    if (errorLoadingItem != null) setErrorLoadingItem(null);
  };

  const calculateItemCost = (item) => {
    let price = item.price;

    if (item.selectedModifierGroups && item.selectedModifierGroups.length) {
      for (let i = 0; i < item.selectedModifierGroups.length; i++) {
        const modifierGroupElem = item.selectedModifierGroups[i];

        if (
          modifierGroupElem.selectedItems &&
          modifierGroupElem.selectedItems.length
        ) {
          for (let j = 0; j < modifierGroupElem.selectedItems.length; j++) {
            const selectedItemElem = modifierGroupElem.selectedItems[j];

            let subPrice = calculateItemCost(selectedItemElem);
            price += subPrice;
          }
        }
      }
    }

    price = price * (item.quantity || 1);

    return price;
  };

  const handleClickOpen = (menuItem) => () => {
    setOpenBogoDialog(false);
    setSelectedItem(menuItem); // View item details
  };

  const handleDeals = () => {
    // handle bogo deal
    if (customHeader) {
      const { grantedItems, grantingItems } = customHeader;
      const gg = grantedItems[0]?.value / grantingItems[0]?.value;

      let remainingGrantingItemsCounter = gg;
      const grantingItemsIds = grantingItems?.map((itemElem) => itemElem._id);
      cartItems.map((cartItemElem) => {
        if (grantingItemsIds && grantingItemsIds.includes(cartItemElem.itemId))
          remainingGrantingItemsCounter = remainingGrantingItemsCounter - (cartItemElem.quantity || 1);
      });
      if (remainingGrantingItemsCounter < 0) remainingGrantingItemsCounter = 0;
      setRemainingGrantingItems(remainingGrantingItemsCounter);

      if (remainingGrantingItemsCounter > 0) {
        removeDeal({ dealId: customHeader._id, type: DealTypes.bogo });
      }
    }

    // handle spend x get y deals
    if (deals && deals.length) handleCartDeals(deals);
  };

  useEffect(() => {
    handleDeals();
  }, [cartItems, customHeader, deals]);

  const pluralize = (text) => (text > 1 ? "s" : "");

  const getDealBuyGetSentence = (deal) => {
    if (deal === null) return "";

    const { grantedItems, grantingItems } = deal;
    const gg = grantedItems[0].value / grantingItems[0].value;
    const gd = 1;

    return `${gg} acheté${pluralize(gg)} = ${gd} offert${pluralize(gd)}`;
  };

  const getRandomId = () => Math.round(Math.random() * 1000000);

  const addToCart = () => {
    const instanceId = getRandomId();
    addItem(currentResto.id, {
      ...customisationItem,
      totalPrice: customisationPrice,
      header: selectedItem.header,
      isGranted: selectedItem.isGranted,
      instanceId,
    });
    handleRemoveSelectedItem();

    if (selectedItem.isGranted) {
      addDeal({
        dealId: customHeader._id,
        promoType: "deal",
        discountItems: [
          {
            itemId: instanceId,
            discountedQuantity: customisationItem.quantity || 1,
            title: customisationItem.title,
          },
        ],
        type: DealTypes.bogo,
        discount: customisationItem.price,
      });
    }
  };

  return (
    <>
      <div className="c-restaurant-header container mx-auto px-4">
        {loading ? (
          <>
            <Stack spacing={1}>
              <Skeleton variant="rounded" width={"100%"} height={288} />
              <Skeleton variant="text" sx={{ fontSize: "2rem" }} />
            </Stack>
          </>
        ) : error ? (
          <MuiAlert severity="error">{error}!</MuiAlert>
        ) : (
          currentResto && (
            <>
              <div className="restaurant-header__banner">
                <div className="restaurant-header__banner--cover">
                  <img
                    src={
                      currentResto.image
                        ? currentResto?.image.url
                        : require("@/assets/svgs/logo.svg").default
                    }
                    alt={`${currentResto.nom} - ${currentResto.adress.city}`}
                  />
                  <h1>
                    {currentResto.nom} - {currentResto.adress.city}
                  </h1>
                </div>
                <div className="restaurant-header__banner--adress">
                  <span>
                    {currentResto.adress.zipCode},{" "}
                    {currentResto.adress.streetAdress}
                  </span>
                </div>
              </div>
            </>
          )
        )}

        <StickyContainer>
          <Sticky enabled={true}>
            {({ style, wasSticky, distanceFromTop }) => {
              setIsSticky(wasSticky);
              setDistanceFromTop(distanceFromTop);

              return (
                <div
                  className={classnames("restaurant-header__categories", {
                    "shadow-md w-full px-11 bg-white": wasSticky,
                  })}
                  style={{
                    ...style,
                    width: wasSticky ? "100%" : style.width,
                    left: wasSticky ? "0" : style.left,
                  }}
                >
                  <div className="flex relative container  mx-auto justify-between bg-white">
                    <Stack
                      direction="row"
                      spacing={1}
                      flexWrap="wrap"
                      overflow={"hidden"}
                      height="3rem"
                    >
                      {customHeader && (
                        <LinkScroll
                          key={customHeader._id}
                          spy={true}
                          hashSpy={true}
                          activeClass="active"
                          to={customHeader._id}
                          smooth={true}
                          offset={-130}
                          className="restaurant-header__category"
                        >
                          Offre
                        </LinkScroll>
                      )}
                      {restaurantHeaders.map(
                        (headerElem, idx) =>
                          headerElem.header && (
                            <LinkScroll
                              key={idx}
                              spy={true}
                              hashSpy={true}
                              activeClass="active"
                              to={headerElem.headerId}
                              smooth={true}
                              offset={-130}
                              className="restaurant-header__category"
                            >
                              {headerElem.header}
                            </LinkScroll>
                          )
                      )}
                    </Stack>
                    <Chip
                      onClick={() => setOpenMore(!openMore)}
                      sx={{
                        backgroundColor: "rgba(248,248,248,1)",
                        color: "#000",
                        fontWeight: 700,
                        fontSize: "0.875rem",
                        marginBottom: "0.75rem",
                        padding: "0.75rem",
                        height: "43px",
                        borderRadius: "9999px",
                        cursor: "pointer",
                      }}
                      label={"Plus"}
                      ref={moreButtonRef}
                    />
                    {openMore && (
                      <ul
                        className="restaurant-header__category_more"
                        ref={mobileMenuRef}
                      >
                        {customHeader && (
                          <LinkScroll
                            key={`more-${customHeader._id}`}
                            spy={true}
                            hashSpy={true}
                            activeClass="active"
                            to={customHeader._id}
                            smooth={true}
                            offset={-130}
                          >
                            <span className="mr-3 mb-3 flex text-center rounded-full font-open font-bold text-sm p-3 px-5 cursor-pointer  text-black">
                              {getDealBuyGetSentence(customHeader)}
                            </span>
                          </LinkScroll>
                        )}
                        {restaurantHeaders.map(
                          (headerElem, idx) =>
                            headerElem.header && (
                              <LinkScroll
                                key={`more-${idx}`}
                                spy={true}
                                hashSpy={true}
                                activeClass="active"
                                to={headerElem.headerId}
                                smooth={true}
                                offset={-130}
                              >
                                <span className="mr-3 mb-3 flex text-center rounded-full font-open font-bold text-sm p-3 px-5 cursor-pointer  text-black">
                                  {headerElem.header}
                                </span>
                              </LinkScroll>
                            )
                        )}
                      </ul>
                    )}
                  </div>
                </div>
              );
            }}
          </Sticky>
          {!restoAvailability?.restaurantActif && (
            <>
              <MuiAlert severity="info">
                {restoAvailability?.closureReason?.message}
              </MuiAlert>
              <br />
            </>
          )}
          {loadingRestoMenus ? (
            <>
              <Skeleton
                variant="text"
                width={"30%"}
                sx={{ fontSize: "2rem", mb: 1 }}
              />
              <Grid
                gridTemplateColumns={"repeat(3, minmax(0, 1fr))"}
                container
                wrap="wrap"
                gap={"1.5rem"}
              >
                {Array(5)
                  .fill(null)
                  .map((_, idx) => (
                    <Skeleton
                      key={idx}
                      variant="rounded"
                      width={485}
                      height={140}
                    />
                  ))}
              </Grid>
            </>
          ) : errorMenusRestos ? (
            <MuiAlert severity="error">{errorMenusRestos}!</MuiAlert>
          ) : (
            <>
              {customHeader && (
                <div>
                  <div style={{ width: "40%" }}>
                    {remainingGrantingItems > 0 ? (
                      <Alert
                        type={"info"}
                        text={
                          <>
                            Sélectionnez{" "}
                            <b>{`${remainingGrantingItems} produit${pluralize(
                              remainingGrantingItems
                            )} `}</b>
                            pour bénéficier de l'offre.
                          </>
                        }
                      />
                    ) : cartDeals &&
                      cartDeals.some(
                        (dealElem) => dealElem.dealId === customHeader._id
                      ) ? (
                      <Alert
                        type={"success"}
                        text={
                          <>
                            Vous bénéficiez de l'offre ! Vous avez choisi le
                            produit offert{" "}
                            <b>
                              {" "}
                              {
                                cartDeals.find(
                                  (dealElem) =>
                                    dealElem.dealId === customHeader._id
                                ).discountItems[0].title
                              }
                            </b>
                          </>
                        }
                      />
                    ) : (
                      <Alert
                        type={"success"}
                        text={
                          <>
                            Vous pouvez choisir votre produit offert !{" "}
                            <b
                              onClick={() => setOpenBogoDialog(true)}
                              style={{ cursor: "pointer" }}
                            >
                              {" "}
                              Choisir votre produit
                            </b>
                          </>
                        }
                      />
                    )}
                  </div>
                  <Element
                    key={`menu-${customHeader._id}`}
                    name={customHeader._id}
                  >
                    <h2 className="card-lists__title">
                      {getDealBuyGetSentence(customHeader)}
                    </h2>

                    <div className="card-lists">
                      {customHeader.grantingItems.map((itemElem, itemIndex) => (
                        <div
                          className="card-list relative"
                          key={`menu-bogo-${itemIndex}`}
                          onClick={handleClickOpen({
                            header: "",
                            item: itemElem,
                          })}
                        >
                          <div className="card-list__header">
                            {itemElem.image && (
                              <div
                                className="card-list__img"
                                style={{
                                  backgroundImage: `url(${itemElem?.image?.url})`,
                                }}
                              ></div>
                            )}
                          </div>
                          <div className="card-list__content">
                            <div className="card-list__infos">
                              <h3>{itemElem.title}</h3>
                              <span className="card-list__price">
                                {itemElem.priceInfo?.text}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{ top: "10px", right: "10px" }}
                            className="absolute flex z-10 bg-black rounded-full w-10 h-10 items-center justify-center hover:bg-opacity-75"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              strokeWidth={3}
                              stroke="#fff"
                              className="w-4 h-4"
                              width={15}
                              height={15}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                              />
                            </svg>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Element>
                </div>
              )}
              <div style={{ width: "40%" }}>
                {deals.map(
                  (dealElem) =>
                    dealElem.type === DealTypes.spend_x_get_y && (
                      <Alert
                        type={"info"}
                        text={
                          <>
                            Bénéficiez de{" "}
                            {dealElem.discount.value
                              ? `${dealElem.discount.value} €`
                              : `${dealElem.discount.percentage * 100} %`}{" "}
                            offert à partir de{" "}
                            {dealElem.minAmount
                              ? `${dealElem.minAmount} €`
                              : ""}
                            .
                          </>
                        }
                      />
                    )
                )}
              </div>
              {restaurantHeaders.map(
                (headerElem, idx) =>
                  headerElem.header && (
                    <Element key={`menu-${idx}`} name={headerElem.headerId}>
                      <h2 className="card-lists__title">{headerElem.header}</h2>

                      <div className="card-lists">
                        {headerElem.items.map((itemElem, idx2) => (
                          <div
                            className="card-list relative"
                            key={`menu-${idx}-${idx2}`}
                            onClick={handleClickOpen({
                              header: headerElem.header,
                              item: itemElem,
                            })}
                          >
                            <div className="card-list__header">
                              {itemElem.image && (
                                <div
                                  className="card-list__img"
                                  style={{
                                    backgroundImage: `url(${itemElem.image.url})`,
                                  }}
                                ></div>
                              )}
                            </div>
                            <div className="card-list__content">
                              <div className="card-list__infos">
                                <h3>{itemElem.title}</h3>
                                <span className="card-list__price">
                                  {itemElem.priceInfo?.price?.text}
                                </span>
                              </div>
                            </div>
                            <div
                              style={{ top: "10px", right: "10px" }}
                              className="absolute flex z-10 bg-black rounded-full w-10 h-10 items-center justify-center hover:bg-opacity-75"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                strokeWidth={3}
                                stroke="#fff"
                                className="w-4 h-4"
                                width={15}
                                height={15}
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 4.5v15m7.5-7.5h-15"
                                />
                              </svg>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Element>
                  )
              )}
            </>
          )}
        </StickyContainer>
        <div
          className={classnames(
            "restaurant-header__categories--mobile lg:hidden fixed bottom-3 z-50 flex justify-between items-center px-4 py-3 left-0 ml-2 right-0 mr-2 shadow-md",
            {
              hidden: cartItems.length < 1 || distanceFromTop > 330,
            }
          )}
          onClick={() => navigate("/cart")}
        >
          <div className="cart-count">
            <span className="cart-count__number">{cartItems.length}</span>
          </div>
          <div className="cart-text">Voir la commande</div>
          <div className="cart-price">
            <span className="cart-price__number">
              {(cartTotal / 100).toFixed(2)} €
            </span>
          </div>
        </div>
      </div>
      {/* {currentResto.legalLink.length > 0 ? ( */}
      {currentResto?.legalLink && (
        <p style={{ padding: "10%" }}>
          Pour être informé sur les allergènes présents dans les produits
          proposés par ce restaurant,
          <a
            target="_blank"
            style={{ color: "green" }}
            href={currentResto?.legalLink}
          >
            &nbsp; Cliquez-ici
          </a>
        </p>
      )}
      {/* //  ) : (
        " "
      )} */}
      <RestaurantContext.Provider
        value={{
          details,
          restoAvailability,
          setErrorLoadingItem,
        }}
      >
        <Dialog
          open={open}
          onClose={handleRemoveSelectedItem}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          fullWidth
          sx={{
            "& .MuiDialog-paper": {
              margin: "15px",

              "@media screen and (max-width: 425px)": {
                width: "100%",
              },
            },
          }}
        >
          <DialogTitle
            id="scroll-dialog-title"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "1.125rem",
                color: "rgba(34,34,34,1))",
                letterSpacing: ".025em",
                lineHeight: "1.75rem",
              }}
            >{`${selectedItem?.header} - ${selectedItem?.item.title}`}</Typography>
            <IconButton
              aria-label="close"
              size="small"
              onClick={handleRemoveSelectedItem}
            >
              <Close fontSize="small" sx={{ fontSize: "1.5rem" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers={scroll === "paper"} tabIndex={-1}>
            <MenuCustomizationForm
              details={details}
              setDetails={setDetails}
              selectedItem={selectedItem}
              customisationItem={customisationItem}
              setCustomisationItem={setCustomisationItem}
              setIsAddBtnDisabled={setIsAddBtnDisabled}
              currentResto={currentResto}
            />
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Button
              sx={{
                display: "flex",
                width: "100%",
                height: "47px",
                borderColor: "rgba(160,174,192,1)",
                borderWidth: "2px",
                color: "rgba(160,174,192,1)",
                textTransform: "capitalize",
                fontSize: "0.875rem",
                fontWeight: 700,
                borderRadius: ".375rem",

                "&:hover": {
                  borderColor: "rgba(113,128,150,1)",
                  color: "rgba(113,128,150,1)",
                  borderWidth: "2px",
                },
              }}
              variant="outlined"
              onClick={handleRemoveSelectedItem}
            >
              Annuler
            </Button>
            <Button
              sx={{
                display: "flex",
                width: "100%",
                height: "47px",
                textTransform: "capitalize",
                fontSize: "0.875rem",
                fontWeight: 700,
                backgroundColor: colors.ui.primary,
                borderRadius: ".375rem",

                "&:hover": {
                  backgroundColor: "#279973",
                },
              }}
              variant="contained"
              onClick={addToCart}
              disabled={
                isNaN(customisationPrice) ||
                isAddBtnDisabled ||
                !selectedItem ||
                !restoAvailability?.restaurantActif ||
                !customisationItem.availabilityInfo.isAvailable
              }
            >
              {errorLoadingItem !== null && errorLoadingItem.code
                ? "Indisponible"
                : isNaN(customisationPrice)
                ? "Chargement en cours"
                : customisationItem &&
                  customisationItem.availabilityInfo &&
                  customisationItem.availabilityInfo.isAvailable
                ? `Ajouter (${(customisationPrice / 100).toFixed(2)} €)`
                : customisationItem.availabilityInfo &&
                  customisationItem.availabilityInfo.reason}
            </Button>
          </DialogActions>
        </Dialog>
        {customHeader && (
          <Dialog
            open={openBogoDialog}
            onClose={() => {
              setOpenBogoDialog(false);
              setCancelAutoOpenBogoDialog(true);
            }}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth
            height="300px"
            sx={{
              "& .MuiDialog-paper": {
                margin: "15px",

                "@media screen and (max-width: 425px)": {
                  width: "100%",
                },
              },
            }}
          >
            <DialogTitle
              id="scroll-dialog-title"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "1.125rem",
                  color: "rgba(34,34,34,1))",
                  letterSpacing: ".025em",
                  lineHeight: "1.75rem",
                }}
              >
                Choisissez votre produit offert
              </Typography>
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => {
                  setOpenBogoDialog(false);
                  setCancelAutoOpenBogoDialog(true);
                }}
              >
                <Close fontSize="small" sx={{ fontSize: "1.5rem" }} />
              </IconButton>
            </DialogTitle>
            <DialogContent
              style={{ minHeight: "300px" }}
              dividers={scroll === "paper"}
              tabIndex={-1}
            >
              <Element key={`menu-granted-${customHeader._id}`}>
                <div className="card-lists">
                  {customHeader.grantedItems.map((itemElem, itemIndex) => (
                    <div
                      onClick={handleClickOpen({
                        header: "",
                        isGranted: true,
                        item: itemElem,
                      })}
                    >
                      <Card
                        sx={{ maxWidth: 345 }}
                        style={{ maxWidth: "230px" }}
                      >
                        <CardMedia
                          sx={{ height: 140 }}
                          image={itemElem?.image?.url}
                          title="green iguana"
                        />
                        <CardContent>
                          <Typography gutterBottom variant="h6" component="div">
                            {itemElem.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {itemElem.priceInfo?.text}
                          </Typography>
                        </CardContent>
                      </Card>
                    </div>
                  ))}
                </div>
              </Element>
            </DialogContent>
          </Dialog>
        )}
      </RestaurantContext.Provider>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  authUser: selectAuthUser,
  cart: selectCart,
  cartDeals: selectCartDeals,
  cartItems: selectCartItems,
  cartTotal: selectCartTotal,
});

const mapDispatchToProps = (dispatch) => ({
  addItem: (restaurantId, item) =>
    dispatch(cartSliceActions.addItem({ restaurantId, item })),
  saveRestaurantId: (restaurantId) =>
    dispatch(authSliceActions.saveRestaurantId(restaurantId)),
  addDeal: (deal) => dispatch(cartSliceActions.addDeal({ deal })),
  handleCartDeals: (deals) =>
    dispatch(cartSliceActions.handleCartDeals({ deals })),
  removeDeal: (deal) => dispatch(cartSliceActions.removeDeal({ deal })),
  clearBogoDeals: () => dispatch(cartSliceActions.clearBogoDeals()),
  clearAllDeals: () => dispatch(cartSliceActions.clearAllDeals()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CRestaurantHeader);
