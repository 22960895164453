import { createSelector } from "reselect";
import { DealTypes } from "../../../features/misc/containers/Restaurants/RestaurantHeader/RestaurantHeader.container";

export const selectCart = (state) => state.cart;

export const selectCartItems = createSelector(
  [selectCart],
  (cart) => cart.cartItems
);

export const selectCartDeals = createSelector(
  [selectCart],
  (cart) => cart.activeDeals
);

export const selectCartItemsCount = createSelector(
  [selectCartItems],
  (cartItems) =>
    cartItems.reduce(
      (accumulatedQuantity, cartItem) =>
        accumulatedQuantity + cartItem.quantity,
      0
    )
);

export const selectCartTotal = createSelector([selectCartItems, selectCartDeals], (cartItems, cartDeals = []) =>
  {  
    const bogoDeal = cartDeals.find(dealElem => dealElem.type === DealTypes.bogo)
    
    let totalCart = cartItems.reduce((accumulatedQuantity, cartItem) => {
      let tempAccumulatedQuantity = accumulatedQuantity + cartItem.quantity * cartItem.totalPrice

      if (bogoDeal && bogoDeal.discountItems[0].itemId === cartItem.instanceId) tempAccumulatedQuantity -= cartItem.price
      return (
        tempAccumulatedQuantity
      );
    }, 0)

    let spendXgetYReductions = 0

      for (let i = 0; i < cartDeals.length; i++) {
        const dealElem = cartDeals[i];
        if (dealElem.type === DealTypes.spend_x_get_y) {
  
          if (dealElem.discount.value) spendXgetYReductions += dealElem.discount.value * 100
          else if (dealElem.discount.percentage) spendXgetYReductions += dealElem.discount.percentage * totalCart
        } 
      } 

    return totalCart > spendXgetYReductions ? totalCart - spendXgetYReductions : 0
  }
);

export const selectIncludeDisposableItems = createSelector(
  [selectCart],
  (cart) => cart.includeDisposableItems
);