import React, { useContext } from "react";
import { Chip, FormGroup, RadioGroup, Typography } from "@mui/material";

import MainModifierOptionWithQty from "../../uic/MainModifierOptionWithQty.uic";
import MainModifierOptionRadioWithQty from "../../uic/MainModifierOptionRadioWithQty.uic";
import { useState, useEffect } from "react";

const ReusableModifierGroup = ({
  modifierGroupId,
  modifierGroupIndex,
  selectedModifierGroups,
  setSelectedModifierGroups,
  setOptionsQuantityCheckList,
  optionsQuantityCheckList,
}) => {
  const [selectedItems, setSelectedItems] = useState();
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [disabledPermittedUnique, setDisabledPermittedUnique] = useState(false);
  const [disableAddQty, setDisableAddQty] = useState(false);
  const [disableRemoveQty, setDisableRemoveQty] = useState(false);
  const [totalSelectedModifiersQuantity, setTotalSelectedModifiersQuantity] =
    useState(0);

  useEffect(() => {
    let newSelectedModifierGroups = selectedModifierGroups && [
      ...selectedModifierGroups,
    ];
    if (!newSelectedModifierGroups) newSelectedModifierGroups = [];

    let selectedModIndex =
      newSelectedModifierGroups &&
      newSelectedModifierGroups.findIndex(
        (modifierGroupElem) =>
          modifierGroupElem.modifierGroupId === modifierGroupId._id
      );

    if (selectedModIndex === -1) {
      const newModifierGroup = {
        modifierGroupId: modifierGroupId._id,
        title: modifierGroupId.title,
        externalId: modifierGroupId.externalId,
        externalData: modifierGroupId.externalData,
        index: modifierGroupIndex + 1,
        selectedItems,
      };
      newSelectedModifierGroups.push(newModifierGroup);
      selectedModIndex = newSelectedModifierGroups.length - 1;
    } else
      newSelectedModifierGroups[selectedModIndex].selectedItems = selectedItems;

    if (
      newSelectedModifierGroups[selectedModIndex].selectedItems &&
      newSelectedModifierGroups[selectedModIndex].selectedItems.length
    ) {
      setSelectedModifierGroups(newSelectedModifierGroups);
    } else {
      newSelectedModifierGroups.splice(selectedModIndex, 1);
      setSelectedModifierGroups(newSelectedModifierGroups);
    }

    handleDisablePermitted();
    handleDisablePermittedUnique();
  }, [selectedItems]);

  const handleDisablePermitted = () => {
    let disableAdd = false;
    let disableRemove = false;

    let totalQuantity = 0;
    if (selectedItems) {
      if (selectedItems) {
        for (let i = 0; i < selectedItems.length; i++) {
          totalQuantity += selectedItems[i].quantity;
        }
      }

      if (modifierGroupId.quantityInfo.maxPermitted <= totalQuantity)
        disableAdd = true;
      if (modifierGroupId.quantityInfo.minPermitted >= totalQuantity)
        disableRemove = true;
    }

    setTotalSelectedModifiersQuantity(totalQuantity);
    setDisableAddQty(disableAdd);
    setDisableRemoveQty(disableRemove);
  };

  const handleDisablePermittedUnique = () => {
    let disable = false;
    if (
      selectedItems &&
      modifierGroupId.quantityInfo.maxPermittedUnique === selectedItems.length
    )
      disable = true;
    setDisabledPermittedUnique(disable);
  };

  const getRadioChipText = () => {
    let chipText = "Optionnel";

    if (
      modifierGroupId.quantityInfo.minPermittedUnique === 1 &&
      modifierGroupId.quantityInfo.maxPermittedUnique === 1
    )
      chipText = "Obligatoire";
    else if (
      modifierGroupId.quantityInfo.minPermitted === 1 &&
      modifierGroupId.quantityInfo.maxPermitted === 1
    )
      chipText = "Obligatoire";

    return chipText;
  };

  const getQuantityChipText = () => {
    let finalText = "";

    let permittedText = "";

    if (modifierGroupId.quantityInfo.minPermitted > 0) {
      if (modifierGroupId.quantityInfo.maxPermitted < 99)
        permittedText = `Choisissez de ${modifierGroupId.quantityInfo.minPermitted} jusqu'à ${modifierGroupId.quantityInfo.maxPermitted}`;
      else
        permittedText = `Choisissez au moins ${modifierGroupId.quantityInfo.minPermitted}`;
    } else if (modifierGroupId.quantityInfo.maxPermitted < 99)
      permittedText = `Choisissez jusqu'à ${modifierGroupId.quantityInfo.maxPermitted}`;

    let permittedUniqueText = "";
    if (modifierGroupId.quantityInfo.minPermittedUnique > 0) {
      if (modifierGroupId.quantityInfo.maxPermittedUnique < 99)
        permittedUniqueText = `à partir de ${modifierGroupId.quantityInfo.minPermittedUnique} à ${modifierGroupId.quantityInfo.maxPermittedUnique} choix différents`;
      else
        permittedUniqueText = `à partir d'au moins ${modifierGroupId.quantityInfo.minPermittedUnique} choix différents`;
    } else if (modifierGroupId.quantityInfo.maxPermittedUnique < 99)
      permittedUniqueText = `de jusqu'à ${modifierGroupId.quantityInfo.maxPermittedUnique} choix différents`;

    const permittedUniqueExists = permittedUniqueText.length > 0;
    if (permittedText.length > 0) {
      if (permittedUniqueExists)
        finalText = `${permittedText}, ${permittedUniqueText}`;
      else finalText = permittedText;
    } else if (permittedUniqueExists)
      finalText = `Choisissez ${permittedUniqueText}`;

    if (!finalText.length) finalText = "Optional";

    return finalText;
  };

  const showRadioGroup = () => {
    if (
      modifierGroupId.quantityInfo.maxPermittedUnique === 1 ||
      modifierGroupId.quantityInfo.maxPermitted === 1
    )
      return true;
    return false;
  };

  const showTypography = () => {
    return (
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "1.125rem",
          marginBottom: "0.5rem",
          color: "rgba(34,34,34,1)",
        }}
        variant="h5"
        component="div"
      >
        {modifierGroupId.title}{" "}
        {showRadioGroup() ? (
          <Chip label={getRadioChipText()} />
        ) : (
          <Chip label={getQuantityChipText()} />
        )}
      </Typography>
    );
  };

  return (
    <div className="menu-customisation__section">
      {showTypography()}
      {showRadioGroup() ? (
        <RadioGroup name={modifierGroupId._id}>
          {modifierGroupId.modifierOptions.map((modifierOption, idx) => {
            return (
              <MainModifierOptionRadioWithQty
                modifierGroupId={`${modifierGroupId._id}-${idx}-`}
                modifierOptionIndex={idx}
                modifierOption={modifierOption}
                setSelectedItems={setSelectedItems}
                selectedOptionId={selectedOptionId}
                setSelectedOptionId={setSelectedOptionId}
                parentsQuantityCheckList={optionsQuantityCheckList}
                setParentQuantityCheckList={setOptionsQuantityCheckList}
              />
            );
          })}
        </RadioGroup>
      ) : (
        <FormGroup>
          {modifierGroupId.modifierOptions.map((modifierOption, idx) => {
            return (
              <MainModifierOptionWithQty
                modifierGroupId={`${modifierGroupId._id}-${idx}-`}
                modifierOptionIndex={idx}
                modifierGroupQuantityInfo={modifierGroupId.quantityInfo}
                modifierOption={modifierOption}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                totalSelectedModifiersQuantity={totalSelectedModifiersQuantity}
                disabledPermittedUnique={disabledPermittedUnique}
                disableAddQty={disableAddQty}
                disableRemoveQty={disableRemoveQty}
                setParentQuantityCheckList={setOptionsQuantityCheckList}
                parentsQuantityCheckList={optionsQuantityCheckList}
              />
            );
          })}
        </FormGroup>
      )}
    </div>
  );
};

export default ReusableModifierGroup;
