const SuccessIcon = () => {
  return (
    <svg
      className="h-5 w-5 text-green-400"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-10.293l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L9 11.586l4.293-4.293a1 1 0 011.414 1.414z" />
    </svg>
  );
};

export default SuccessIcon;
