import { createSelector } from "reselect";

export const selectAuth = (state) => state.auth;

export const selectAuthErrors = createSelector(
  [selectAuth],
  (auth) => auth.errors
);

export const selectIsAuthenticated = createSelector(
  [selectAuth],
  (auth) => !!auth.token
);

export const selectAuthToken = createSelector(
  [selectAuth],
  (auth) => auth.token
);

export const selectRestaurantId = createSelector(
  [selectAuth],
  (auth) => auth.restaurantId
);

export const selectAuthUser = createSelector([selectAuth], (auth) => auth.user);
