import { useState, useEffect } from "react";
import { Box, ButtonGroup, IconButton, TextField } from "@mui/material";
import classnames from "classnames";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

import { colors } from "@/theme/globalVariables";

const AddRemoveElement = ({
  defaultValue = 1,
  minValue = 0,
  maxValue = 1,
  disabled = false,
  disableAddQty = false,
  disableRemoveQty = false,
  width = 96,
  setQty,
}) => {
  const [value, setValue] = useState(defaultValue);

  const handleAdd = () => {
    if (maxValue !== null) {
      if (value < maxValue) {
        setQty(value + 1);
        setValue(value + 1);
      }
    } else {
      setQty(value + 1);
      setValue(value + 1);
    }
  };

  const handleRemove = () => {
    if (value > minValue) {
      setQty(value - 1);
      setValue(value - 1);
    }
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <Box onClick={(e) => e.stopPropagation()}>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        size="small"
        sx={{
          width: `${width}px`,
          height: "30px",
          boxShadow: "none",
          gap: "3px",
        }}
      >
        <div
          onClick={() => {
            if (
              !(
                value === minValue ||
                value === 1 ||
                disabled ||
                disableRemoveQty
              )
            ) {
              handleRemove();
            }
          }}
          className={classnames(
            "flex h-8 w-8 items-center self-center justify-center bg-black rounded-full select-none cursor-pointer ease-in-out duration-75 hover:opacity-75",
            {
              "bg-opacity-25 cursor-not-allowed":
                value === minValue ||
                value === 1 ||
                disabled ||
                disableRemoveQty,
            }
          )}
          style={{ width: "30px", height: "30px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            strokeWidth={3}
            stroke="#fff"
            className="w-4 h-4"
            width={2}
            height={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
          </svg>
        </div>
        {/* <IconButton
          disabled={value === minValue || value === 1 || disabled || disableRemoveQty}
          aria-label="remove"
          size="small"
          onClick={handleRemove}
          sx={{
            backgroundColor: colors.ui.primary,
            borderRadius: 0,
            borderBottomLeftRadius: "4px",
            borderTopLeftRadius: "4px",
            color: "#fff",

            "&:hover": {
              backgroundColor: "#279973",
            },
          }}
        >
          <RemoveIcon fontSize="small" />
        </IconButton> */}
        <TextField
          InputProps={{
            readOnly: true,
          }}
          value={value}
          variant="outlined"
          sx={{
            borderRadius: 0,
            width: "calc(100% - 60px)",

            "& input": {
              padding: "0.25rem 0.75rem",
              borderRadius: 0,
              background: "#fff", //"#eee",
              textAlign: "center",
            },

            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        />
        <div
          onClick={() => {
            if (
              !(
                (maxValue !== null ? value === maxValue || disabled : false) ||
                disableAddQty
              )
            )
              handleAdd();
          }}
          className={classnames(
            "flex h-8 w-8 items-center self-center justify-center bg-black rounded-full select-none cursor-pointer ease-in-out duration-75 hover:opacity-75",
            {
              "bg-opacity-25 cursor-not-allowed":
                (maxValue !== null ? value === maxValue || disabled : false) ||
                disableAddQty,
            }
          )}
          style={{ width: "30px", height: "30px" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            strokeWidth={3}
            stroke="#fff"
            className="w-4 h-4"
            width={2}
            height={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 4.5v15m7.5-7.5h-15"
            />
          </svg>
        </div>
        {/* <IconButton
          disabled={(maxValue !== null ? value === maxValue || disabled : false) || disableAddQty}
          aria-label="add"
          size="small"
          onClick={handleAdd}
          sx={{
            backgroundColor: colors.ui.primary,
            borderRadius: 0,
            borderBottomRightRadius: "4px",
            borderTopRightRadius: "4px",
            color: "#fff",

            "&:hover": {
              backgroundColor: "#279973",
            },
          }}
        >
          <AddIcon fontSize="small" />
        </IconButton> */}
      </ButtonGroup>
    </Box>
  );
};

export default AddRemoveElement;
