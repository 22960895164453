import Axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL

export const login = (email, password) => {
  return Axios.post(
    `${baseUrl}/v1/auth/login-user`,
    {
      email,
      password,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const register = (email, password, prenom, nom, telephone) => {
  return Axios.post(
    `${baseUrl}/v1/auth/register-user`,
    {
      email,
      password,
      prenom,
      nom,
      telephone,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

export const getUserInfo = (token) => {
  return Axios.get(`${baseUrl}/v1/user/info`, {
    headers: {
      Authorization: token,
    },
  });
};
