import { configureStore, MiddlewareArray } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import { createLogger } from "redux-logger";

import { isDev } from "@/utils/devTools";
import { cartSliceActions } from "@/store/reducers/cart/cart.slice";

import rootReducer from "./reducers/rootReducer";

const loggerMiddleware = createLogger({
  predicate: () => isDev(),
  collapsed: true,
});

// const customMiddleware = (store) => (next) => (action) => {
//   const state = store.getState();
//   const cart = state.cart;

//   if (
//     cart.code &&
//     cart.codeValidity &&
//     new Date().getTime() - cart.codeValidity > 60000 //1800000
//   ) {
//     store.dispatch(cartSliceActions.handleChangeCode(""));
//   } else if (cart.code && !cart.codeValidity) {
//     store.dispatch(cartSliceActions.handleChangeCode(""));
//   }

//   return next(action);
// };

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([loggerMiddleware]),
  devTools: true,
});

export const persistor = persistStore(store);
