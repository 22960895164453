import { createSlice } from "@reduxjs/toolkit";

import * as cartAction from "./cart.actions";

const initialState = {
  cartItems: [],
  restaurantId: null,
  deliveryType: "delivery",
  code: "",
  codeValidity: null,
  includeDisposableItems: false,
  activeDeals: []
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    ...cartAction,
  },
});

export const cartSliceActions = cartSlice.actions;

export default cartSlice.reducer;
